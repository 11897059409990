<template>
    <div class="finder-map">
        <l-map id="mainMap" :ref="isExploreMode() ? 'mapExplore' : 'mapTrip'" :zoom="zoom" :center="latLong"
        @ready="mapReady" @update:zoom="zoomUpdated" @update:center="centerUpdated" @update:bounds="boundsUpdated">
        <md-linear-progress id="mapProgress" class="loaded" value="0"
        aria-label="Map progress"></md-linear-progress>
        
        <div class="map-overlay map-places-list" v-if="isExploreMode()">
            <md-elevated-button @click="openPlacesListDialog">
                <md-icon>view_list</md-icon>
                <span class="counter">{{ markers.length < 99 ? markers.length : '99+' }}</span>
            </md-elevated-button>
        </div>
        
        <div class="map-overlay map-filters" v-if="isExploreMode()">
            <md-elevated-button @click="openFiltersDialog">
                <md-icon>tune</md-icon>
            </md-elevated-button>
        </div>
        
        <div class="map-overlay map-layers">
            <md-elevated-button @click="handleMapLayers">
                <md-icon>layers</md-icon>
            </md-elevated-button>
        </div>
        
        <div class="map-overlay map-search" v-if="isExploreMode()">
            <div id="autocomplete" class="autocomplete-container"></div>
        </div>
        
        <div class="map-overlay map-search-update-places" v-if="isExploreMode()">
            <md-elevated-button @click="manualUpdate">Search in this area</md-elevated-button>
        </div>
        
        <div class="map-overlay map-search-user-position">
            <md-elevated-button @click="getUserLatLong">
                <md-icon>my_location</md-icon>
            </md-elevated-button>
        </div>
        
        <div class="map-overlay map-add-manual-step" v-if="isExploreMode()">
            <md-elevated-button @click="handleCrossingPoint">
                <md-icon>not_listed_location</md-icon>
            </md-elevated-button>
        </div>
        
        <div class="map-overlay map-trip-days-legend" v-if="mode == 'trip'">
            <ul>
                <li v-for="(tripDay, index) in tripDaysLengend" :key="tripDay.index"
                :data-day-index="tripDay.index - 1" @click="setDefaultTripMarkerView(tripDay.index - 1)"
                :style="'background-color: ' + tripDay.color" class="map-trip-days-legend-item">
                <span>Day {{ tripDay.index }}</span>
            </li>
        </ul>
    </div>
</l-map>

<md-dialog id="filtersDialog">
    <div slot="headline">Filters</div>
    <form slot="content" id="filtersForm" method="dialog">
        <label class="hidden">
            <span>Auto mode</span>
            <md-switch id="autoModeSwitch"></md-switch>
        </label>
        
        <h3>By categories</h3>
        <md-chip-set id="placeCategoryChips">
            <md-filter-chip :id="placeCategory.id" :label="placeCategory.label"
            v-for="placeCategory in placeCategories.filter(pc => !pc.hidden)" :key="placeCategory.id"
            @click="setSelectedPlaceCategory(placeCategory.id)" :selected="placeCategory.selectedByDefault">
            <md-icon slot="icon">{{ placeCategory.icon }}</md-icon>
        </md-filter-chip>
    </md-chip-set>
</form>
<div slot="actions">
    <md-text-button form="filtersForm" value="cancel">Close</md-text-button>
</div>
</md-dialog>

<md-dialog id="placeDialog">
    <div slot="headline">
        <div class="place-header">
            <md-icon>{{ markerSelected?.icon }}</md-icon>
            <span>{{ placeSelected?.name }}</span>
            <md-linear-progress id="placeProgressDialog" value="0"
            aria-label="Download progress"></md-linear-progress>
        </div>
    </div>
    <form slot="content" id="placeForm" method="dialog">
        <md-tabs id="placeTabs">
            <md-primary-tab id="infosTab" aria-controls="infos-panel" inline-icon>
                <md-icon slot="icon">info</md-icon>
                Infos
            </md-primary-tab>
            <md-primary-tab id="pictureTab" class="hidden" aria-controls="picture-panel" inline-icon>
                <md-icon slot="icon">local_see</md-icon>
                Picture
            </md-primary-tab>
        </md-tabs>
        
        <div id="infos-panel" role="tabpanel" aria-labelledby="infosTab">
            <div class="place-main-infos">
                <md-list>
                    <md-list-item type="link"
                    :href="'https://en.wikipedia.org/?curid=' + markerSelected?.wikiSearch.pageid"
                    target="_blank" v-if="markerSelected?.wikiSearch">
                    <md-icon slot="start">description</md-icon>
                    <div slot="headline" v-html="markerSelected?.wikiSearch.snippet + ' ...'"></div>
                </md-list-item>
                <md-list-item type="text" v-if="placeSelected?.address_line2">
                    <md-icon slot="start">pin_drop</md-icon>
                    <div slot="headline">{{ placeSelected?.address_line2 }}</div>
                </md-list-item>
                <md-list-item type="text" v-if="placeSelected?.opening_hours">
                    <md-icon slot="start">schedule</md-icon>
                    <div slot="headline">{{ placeSelected?.opening_hours }}</div>
                </md-list-item>
                <md-list-item type="link" v-if="placeSelected?.contact && placeSelected?.contact.email"
                :href="'mailto:' + placeSelected?.contact.email">
                <md-icon slot="start">alternate_email</md-icon>
                <div slot="headline">{{ placeSelected?.contact.email }}</div>
            </md-list-item>
            <md-list-item type="link" v-if="placeSelected?.contact && placeSelected?.contact.phone"
            :href="'tel:' + placeSelected?.contact.phone">
            <md-icon slot="start">call</md-icon>
            <div slot="headline">{{ placeSelected?.contact.phone }}</div>
        </md-list-item>
        <md-list-item type="link" v-if="placeSelected?.website" :href="placeSelected?.website"
        target="_blank">
        <md-icon slot="start">language</md-icon>
        <div slot="headline">{{ placeSelected?.website }}</div>
    </md-list-item>
    <md-list-item type="text" v-if="placeSelected?.categories">
        <md-icon slot="start">category</md-icon>
        <div slot="headline">{{ placeSelected?.categories }}</div>
    </md-list-item>
</md-list>
</div>
</div>
<div id="picture-panel" role="tabpanel" aria-labelledby="pictureTab" hidden>
    <img class="place-picture hidden" :src="markerSelected?.imgUrl" />
</div>
</form>
<div slot="actions">
    <md-text-button form="placeForm" value="ok" @click="sendStepToTrip(false)" v-if="isExploreMode()">Add to
        my trip</md-text-button>
        <md-text-button form="placeForm" value="cancel">Close</md-text-button>
    </div>
</md-dialog>

<md-dialog id="placesSearchedFoundDialog">
    <div slot="headline">Search results</div>
    <form slot="content" id="addressResultsForm" method="dialog">
        <div class="address-results-list">
            <div class="address-result" v-for="placeSearchedFound in placesSearchedFound"
            :key="placeSearchedFound.place_id" @click="validplaceSearched(placeSearchedFound)">
            <md-radio :id="placeSearchedFound.place_id + '-radio'" name="adressResults"
            :value="placeSearchedFound.place_id" aria-hidden="false"></md-radio>
            <label :for="placeSearchedFound.place_id + '-radio'">{{ placeSearchedFound.formatted }}</label>
        </div>
    </div>
</form>
<div slot="actions">
    <md-text-button form="addressResultsForm" value="cancel">Close</md-text-button>
</div>
</md-dialog>

<md-dialog id="placesListDialog">
    <div slot="headline">Places around</div>
    <form slot="content" id="placesListForm" method="dialog">
        <md-list>
            <TransitionGroup name="list" tag="div">
                <md-list-item type="button" v-for="marker in markers" :key="marker.place.place_id"
                @click="goToPlace(marker)">
                <md-icon slot="start">{{ marker.icon }}</md-icon>
                <div slot="headline">{{ marker.place.name }}</div>
                <div slot="supporting-text">{{ marker.place.city }} - {{ marker.place.categories }}</div>
                <div slot="supporting-text">{{ marker.place.address_line1 }} - {{ marker.place.address_line2
                }}</div>
            </md-list-item>
        </TransitionGroup>
    </md-list>
</form>
<div slot="actions">
    <md-text-button form="placesListForm" value="cancel">Close</md-text-button>
</div>
</md-dialog>

<md-dialog id="addStepHereDialog" type="alert">
    <div slot="headline">Add a step here ?</div>
    <form slot="content" id="addStepHereForm" method="dialog"></form>
    <div slot="actions">
        <md-text-button form="addStepHereForm" value="cancel">No</md-text-button>
        <md-text-button form="addStepHereForm" value="ok" @click="sendStepToTrip(true)">Yes</md-text-button>
    </div>
</md-dialog>
</div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';
import { LMap, LTileLayer } from "@vue-leaflet/vue-leaflet";
import { LMarkerClusterGroup } from 'vue-leaflet-markercluster';
import { PolylineDecorator } from 'leaflet-polylinedecorator';
import { GeocoderAutocomplete } from '@geoapify/geocoder-autocomplete';
const nominatimUrl = 'https://nominatim.openstreetmap.org/';

const mapExplore = ref(null);
const mapTrip = ref(null);

export default {
    name: 'FinderMap',
    components: {
        LMap,
        LTileLayer,
    },
    props: [
    'mode',
    'placesSearched',
    'tripSteps',
    'departureNeeded',
    'arrivalNeeded',
    'nightNeeded',
    'placeId',
    ],
    setup() {
        return { mapExplore, mapTrip }
    },
    data() {
        return {
            leafletMap: null,
            bounds: {},
            center: [],
            zoom: 10,
            latLong: [48.8575, 2.3514],
            mapLayers: null,
            mapLayerSelected: 'Default',
            mapLoadingInterval: null,
            mapExploreResized: false,
            mapTripResized: false,
            materialEventsInitialized: false,
            addressAutoCompleteInitialized: false,
            
            countries: require('./json/countries.json'),
            capitals: require('./json/capitals.json'),
            countrySelected: null,
            capitalSelected: null,
            
            placesSuggestion: { features: [] },
            placesSuggestionSearchDone: true,
            defaultPlaceCategories: ['tourism.attraction', 'tourism.sights.castle', 'tourism.sights.tower', 'tourism.sights.archaeological_site', 'tourism.sights.memorial.monument', 'leisure.park', 'building.tourism', 'building.historic', 'entertainment', 'entertainment.culture'],
            selectedPlaceCategories: [],
            placeCategories: require('./json/placeCategories.json'),
            
            markers: [],
            routeLayers: [],
            imageAlreadyLoaded: [],
            
            placeSearch: null,
            placesSearchedFound: [],
            selectedPlaceSearched: null,
            
            markerSelected: null,
            placeSelected: null,
            
            tripMarkers: [],
            tripDaysLengend: [],
            tripDayColors: ['#3876b1', '#5872b6', '#776cb6', '#9465b0', '#ad5da4', '#c25593', '#9467b7', '#4878c5', '#0081b9', '#00849a', '#008274'],
            
            selectedCrossingPoint: null,
            selectedCrossingPointMarker: null,
        }
    },
    mounted() {
        this.init();
        
        if (!this.materialEventsInitialized) {
            CoreService.initDialogs();
            CoreService.initTabs();
            
            this.materialEventsInitialized = true;
        }
    },
    watch: {
        $route(to, from) {
            if (to.name == 'map' && this.isExploreMode() || to.name == 'trip' && this.mode == 'trip') {
                setTimeout(() => {
                    this.resizeMap();
                    
                    if (to.name == 'map' && this.isExploreMode()) {
                        if (!this.addressAutoCompleteInitialized) {
                            this.initAddressAutoComplete();
                        }
                        
                        this.handleRouteParams();
                    }
                }, 100);
            }
        },
        latLong: function (value, oldValue) {
            this.latLongChanged(value, oldValue);
        },
        countrySelected: function (value, oldValue) {
            this.countrySelectedChanged(value, oldValue);
        },
        placesSuggestionSearchDone: function (value, oldValue) {
            if (value) {
                mapProgress.value = 1;
                clearInterval(this.mapLoadingInterval);
                $('.map-places-list').fadeIn();
                
                this.endPlaceSuggestionsSearch();
            }
            else
            this.setMapIsLoading();
        },
        tripSteps: function (value, oldValue) {
            if (typeof value == 'string' && value.length > 0 && this.mode == 'trip') {
                this.updateTripMarkers(JSON.parse(this.tripSteps));
            }
        },
        departureNeeded: function (value, oldValue) {
            if (value && value == 'true' && this.isExploreMode()) {
                this.handleDepartureNeeded();
            }
            else if (this.addressAutoCompleteInitialized) {
                this.resetAutoComplete();
            }
        },
        arrivalNeeded: function (value, oldValue) {
            if (value && value == 'true' && this.isExploreMode()) {
                this.handleArrivalNeeded();
            }
            else if (this.addressAutoCompleteInitialized) {
                this.resetAutoComplete();
            }
        },
        nightNeeded: function (value, oldValue) {
            if (value && value.length > 0 && this.isExploreMode()) {
                this.handleNightNeeded();
            }
            else if (this.addressAutoCompleteInitialized) {
                this.resetAutoComplete();
            }
        },
        placeId: function (value, oldValue) {
            if (value && value.length > 0 && this.isExploreMode()) {
                this.handlePlaceId(value);
            }
        },
    },
    methods: {
        init() {
            this.selectedPlaceCategories = this.placeCategories.filter(pc => pc.selectedByDefault).map(pc => pc.id);
            
            if (this.mode == 'trip') {
                this.initTabs();
            }
            
            else {
                this.resizeMap();
            }
            
            this.onWindowResize();
        },
        
        isExploreMode() {
            return this.mode == 'explore' || typeof this.mode == 'undefined';
        },
        
        // #region EVENTS
        
        mapReady(map) {
            this.leafletMap = map;
            this.initMapLayers();
            this.removeFooter();
            $('.map-overlay').click((e) => { e.stopPropagation() });
            $('.leaflet-pane.leaflet-map-pane').attr('id', 'mainMapPane');
            
            if (this.isExploreMode()) {
                this.initAddressAutoComplete();
                this.handleRouteParams();
            }
        },
        
        zoomUpdated(zoom) {
            this.zoom = zoom;
            this.checkMapUpdated();
        },
        
        centerUpdated(center) {
            this.center = center;
            this.checkMapUpdated();
        },
        
        boundsUpdated(bounds) {
            this.bounds = bounds;
            this.checkMapUpdated();
            
            // Update crossing point marker latlong to the center of the map
            if (this.selectedCrossingPointMarker) {
                let mapTarget = this.isExploreMode() ? mapExplore : mapTrip;
                let latLng = mapTarget.value.leafletObject.getCenter();
                this.selectedCrossingPointMarker.setLatLng([latLng.lat, latLng.lng]);
            }
        },
        
        async countrySelectedChanged(value, oldValue) {
            this.getCountryCapital();
            this.searchCapitalLatLong();
        },
        
        latLongChanged(value, oldValue) {
            console.log('LatLong changed :', value);
        },
        
        onWindowResize() {
            window.addEventListener('resize', () => {
                this.resizeMap(true);
            });
        },
        
        // #endregion
        
        // #region MAP BASE
        
        getMap() {
            let mapTarget = this.isExploreMode() ? mapExplore : mapTrip;
            return mapTarget.value.leafletObject;
        },
        
        initMapLayers() {
            let map = this.getMap();
            
            var standardLayer = L.tileLayer('https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png?api_key=ee1f671a-d9f4-40e3-a5ca-0b8ef906d833', {
                maxZoom: 20,
                attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>'
            });
            
            var satelliteLayer = L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_satellite/{z}/{x}/{y}{r}.jpg?api_key=ee1f671a-d9f4-40e3-a5ca-0b8ef906d833', {
                maxZoom: 20,
                noWrap: true,
                attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>'
            });
            
            this.mapLayers = {
                "Default": standardLayer,
                "Satellite": satelliteLayer
            };
            
            this.mapLayers['Default'].addTo(map);
        },
        
        handleMapLayers() {
            if (this.mapLayerSelected == 'Default') {
                this.switchToSatellite();
            }
            else {
                this.switchToDefault();
            }
        },
        
        switchToSatellite() {
            let map = this.getMap();
            
            map.removeLayer(this.mapLayers[this.mapLayerSelected]);
            this.mapLayerSelected = 'Satellite';
            map.addLayer(this.mapLayers[this.mapLayerSelected]);
        },
        
        switchToDefault() {
            let map = this.getMap();
            
            map.removeLayer(this.mapLayers[this.mapLayerSelected]);
            this.mapLayerSelected = 'Default';
            map.addLayer(this.mapLayers[this.mapLayerSelected]);
        },
        
        checkMapUpdated(delay = 1000) {
            setTimeout(() => {
                let mapUpdateRule = this.placesSuggestionSearchDone && typeof this.bounds._northEast != 'undefined' && this.zoom >= 13 && this.selectedPlaceCategories.length > 0;
                
                if (mapUpdateRule && typeof autoModeSwitch != 'undefined' && autoModeSwitch.selected) {
                    this.placesSuggestionSearchDone = false;
                    this.getPlacesSuggestion();
                    $('.map-search-update-places').fadeOut();
                }
                else if (mapUpdateRule && typeof autoModeSwitch != 'undefined' && !autoModeSwitch.selected) {
                    $('.map-search-update-places').fadeIn();
                }
                
                // Remove markers if zoom is too low and markers not in view
                if (this.isExploreMode() && this.zoom <= 11) {
                    this.deleteLeafletMarkers(mapExplore);
                }
            }, delay);
        },
        
        setMapIsLoading() {
            $('#mapProgress').removeClass('loaded');
            
            this.mapLoadingInterval = setInterval(() => {
                mapProgress.value = (Number(mapProgress.value) + 0.005).toString();
            }, 100);
        },
        
        manualUpdate() {
            $('.map-search-update-places').fadeOut();
            this.placesSuggestionSearchDone = false;
            this.getPlacesSuggestion();
        },
        
        resizeMap(force = false) {
            if (this.isExploreMode() && (!this.mapExploreResized || force)) {
                let height = window.innerHeight - $('nav').outerHeight(true) - 40;
                $('.finder-map #mainMap').height(height).css('opacity', 1);
                
                if (height > 0)
                this.mapExploreResized = true;
                
                setTimeout(() => {
                    if (mapExplore.value.leafletObject)
                    mapExplore.value.leafletObject.invalidateSize();
                }, 1);
            }
            
            else if (this.mode == 'trip' && (!this.mapTripResized || force)) {
                let height = window.innerHeight - $('nav').outerHeight(true) - $('.action-bar').outerHeight(true);
                $('.trip #mainMap').height(height).css('opacity', 1);
                
                if (height > 0)
                this.mapTripResized = true;
                
                setTimeout(() => {
                    if (mapTrip.value && mapTrip.value.leafletObject)
                    mapTrip.value.leafletObject.invalidateSize();
                }, 1);
            }
            
            // PREVENT GREY TILES
            setTimeout(() => {
                if (this.isExploreMode())
                this.endPlaceSuggestionsSearch();
            }, 250);
        },
        
        removeFooter() {
            let footer = document.querySelector('.leaflet-bottom.leaflet-right');
            
            if (footer)
            footer.remove();
        },
        
        getUserLatLong() {
            let vm = this;
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    vm.goTo([position.coords.latitude, position.coords.longitude], 18);
                });
            }
            
            else
            console.log("Geolocation is not supported by this browser.");
        },
        
        getCountryCapital() {
            this.capitalSelected = this.capitals[this.countrySelected?.code];
        },
        
        searchCapitalLatLong() {
            axios.get(nominatimUrl + 'search', {
                params: {
                    q: this.capitalSelected + ', ' + this.countrySelected.name,
                    format: 'json'
                }
            })
            .then(response => {
                this.goTo([response[0].lat, response[0].lon], 15);
            })
        },
        
        async getPlaceByLatLng(lat, lng) {
            return axios.get(nominatimUrl + 'reverse', {
                params: {
                    lat: lat,
                    lon: lng,
                    format: 'json'
                }
            })
            .then(response => response.data)
        },
        
        async getCountryNameInMapView() {
            let mapTarget = this.isExploreMode() ? mapExplore : mapTrip;
            let center = mapTarget.value.leafletObject.getCenter();
            let result = await this.getPlaceByLatLng(center.lat, center.lng);
            
            if (result) {
                return result.address.country;
            }
            else {
                return null;
            }
        },
        
        goTo(latLong, zoom) {
            let mapTarget = this.isExploreMode() ? mapExplore : mapTrip;
            console.log('Go to :', latLong, zoom);
            mapTarget.value.leafletObject.setView(latLong, zoom);
            
            CoreService.scrollToTop();
        },
        
        deleteLeafletMarkers(map, all = false) {
            map.value.leafletObject.eachLayer((layer) => {
                // detect if marker is in view
                
                if (layer instanceof L.Marker || layer instanceof L.Polyline || layer instanceof L.PolylineDecorator) {
                    if (all) {
                        map.value.leafletObject.removeLayer(layer);
                        this.resetFilterOpacityMarker();
                    }
                    else if (typeof layer.getLatLng != 'undefined' && !map.value.leafletObject.getBounds().contains(layer.getLatLng()) || typeof layer.getLatLng == 'undefined' && !map.value.leafletObject.getBounds().contains(layer._latlngs)) {
                        let placeId = $(layer._icon).children().first().attr('data-place-id');
                        this.markers = this.markers.filter(m => m.id != placeId);
                        this.placesSuggestion.features = this.placesSuggestion.features.filter(f => f.properties.place_id != placeId);
                        
                        map.value.leafletObject.removeLayer(layer);
                    }
                }
                
            });
            
            // Update map places list
            if (this.markers.length == 0) {
                $('.map-places-list').fadeOut();
            }
        },
        
        deleteLeafletMarker(map, marker) {
            if (marker) {
                map.value.leafletObject.removeLayer(marker);
            }
        },
        
        // #endregion
        
        // #region PLACES SUGGESTIONS
        
        setSelectedPlaceCategory(id) {
            let chip = placeCategoryChips.chips.find(c => c.id == id);
            
            if (chip && chip.selected) {
                this.selectedPlaceCategories.push(id);
            }
            else {
                this.selectedPlaceCategories = this.selectedPlaceCategories.filter(c => c != id);
            }
        },
        
        getPlacesSuggestion() {
            console.log('Searching places suggestion with these categories :');
            console.log(this.selectedPlaceCategories);
            fetch(`https://api.geoapify.com/v2/places?categories=${this.selectedPlaceCategories.join(',')}&filter=rect:${this.bounds._northEast.lng},${this.bounds._northEast.lat},${this.bounds._southWest.lng},${this.bounds._southWest.lat}&limit=50&apiKey=837da8675243424e83d2fcb7aa570b9b`, { method: 'GET', })
            .then(response => response.json())
            .then(data => {
                // REMOVE UNDEFINED PLACES 
                data.features = data.features.filter(f => f.properties.name);
                
                // UPDATE GLOBAL PLACES
                for (const feature of data.features) {
                    if (!this.placesSuggestion.features.map(f => f.properties.place_id).includes(feature.properties.place_id)) {
                        // replace name by name_international
                        if (feature.properties.name_international && feature.properties.name_international[this.$root.language])
                        feature.properties.name = feature.properties.name_international[this.$root.language];
                        
                        this.placesSuggestion.features.push(feature);
                    }
                }
                
                
                console.log(this.placesSuggestion);
                this.updateMarkers();
                
                setTimeout(() => {
                    this.placesSuggestionSearchDone = true;
                }, 1000);
            })
            .catch(error => console.log('error', error));
        },
        
        goToPlace(marker) {
            if (this.isWikiSearchEnabled(marker)) {
                this.setMarkerWikiInfos(marker, marker.leafletMarker);
            }
            
            this.goTo([marker.lat, marker.lng], 20);
            placesListDialog.close();
            
            setTimeout(() => {
                marker.leafletMarker._icon.click();
            }, 250);
        },
        
        endPlaceSuggestionsSearch() {
            setTimeout(() => {
                $('#mapProgress').addClass('loaded');
                
                if (typeof mapProgress != 'undefined')
                mapProgress.value = 0;
            }, 500);
        },
        
        // #endregion
        
        // #region MARKERS
        
        async updateMarkers() {
            this.deleteLeafletMarkers(mapExplore);
            let places = CoreService.getArray(this.placesSuggestion.features);
            let newMarkers = [];
            
            for (let i = 0; i < places.length; i++) {
                let place = places[i];
                let id = place.properties.place_id;
                
                if (!this.markers.map(m => m.id).includes(id)) {
                    let marker = {
                        id: id,
                        lat: typeof place.properties.lat != 'undefined' ? place.properties.lat : place.geometry.coordinates[1],
                        lng: typeof place.properties.lon != 'undefined' ? place.properties.lon : place.geometry.coordinates[0],
                        name: place.properties.name_international && place.properties.name_international[this.$root.language] ? place.properties.name_international[this.$root.language] : place.properties.name_international && place.properties.name_international['en'] ? place.properties.name_international['en'] : place.properties.name,
                        categories: place.properties.categories.join(', '),
                        imgUrl: '',
                        imgFullUrl: '',
                        icon: this.setMarkerIcon(place.properties),
                        wikiSearch: null,
                        place: place.properties,
                        leafletMarker: null,
                    };
                    
                    this.markers.push(marker);
                    newMarkers.push(marker);
                }
            }
            
            this.buildMarkers(newMarkers);
        },
        
        buildMarkers(markers = null, className = null) {
            let markerProvided = markers != null;
            
            if (!markerProvided)
            markers = this.markers;
            
            for (let i = 0; i < markers.length; i++) {
                let marker = markers[i];
                let iconParam = markerProvided && marker.iconNumber ? [marker.icon, marker.iconNumber, marker.iconNumberColor, marker.dayIndex] : marker.icon;
                
                if (marker.iconClass) {
                    className = marker.iconClass;
                }
                
                let existingMarker = this.addMarker(marker, iconParam, className);
                
                this.setMakerEvents(marker, existingMarker);
                marker.leafletMarker = existingMarker;
                
                if (markerProvided && !this.isExploreMode()) {
                    this.tripMarkers.push(marker);
                }
            }
        },
        
        setMakerEvents(marker, leafletMarker) {
            if (this.isWikiSearchEnabled(marker)) {
                leafletMarker.on('mouseover', () => {
                    this.setMarkerWikiInfos(marker, leafletMarker);
                });
            }
            
            leafletMarker.on('click', () => {
                // Classic marker
                if (marker.place) {
                    this.markerSelected = marker;
                    this.placeSelected = marker.place;
                    
                    // RESET TAB
                    infosTab.click();
                    $('#placeProgressDialog').removeClass('loaded');
                    $('#pictureTab, .place-picture').addClass('hidden');
                    $('#picture-panel .place-picture').removeClass('loaded');
                    $('#placeDialog > [slot="headline"]').css('background-image', ``);
                    
                    placeProgressDialog.value = 0.33;
                    
                    setTimeout(() => {
                        placeProgressDialog.value = 0.6;
                    }, 250);
                    
                    setTimeout(() => {
                        if (typeof marker.imgFullUrl != 'undefined' && marker.imgFullUrl != null && marker.imgFullUrl.length > 0)
                        $('#pictureTab, .place-picture').removeClass('hidden');
                        
                        placeProgressDialog.value = 1;
                        $('#placeProgressDialog').addClass('loaded');
                    }, 1000);
                    
                    placeDialog.show();
                }
                
                // Crossing point marker
                else {
                    this.$root.toastMessage('This is a crossing point');
                }
            });
        },
        
        isWikiSearchEnabled(marker) {
            return marker.place ? marker.place.wiki_and_media && marker.place.wiki_and_media.wikipedia || marker.place.categories.includes('administrative') || marker.place.categories.includes('tourism') || marker.place.categories.includes('heritage') : false;
        },
        
        addMarker(marker, icon, className = null, popupDOM = null) {
            let mapTarget = this.isExploreMode() ? mapExplore : mapTrip;
            let placeId = marker.id ?? marker.place_id;
            
            // HANDLE ICON STEP INDEX
            let iconDOM = typeof icon != 'object' ? `<md-icon data-place-id="${placeId}" class="${className ?? ''}">${icon}</md-icon>` : `<span data-place-id="${placeId}" class="${(className ?? '') + 'marker-icon-number'}" data-day-index="${icon[3]}" style="background-color: ${icon[2]}">${icon[1]}</span>`;
            
            if (popupDOM) {
                return L.marker([marker.lat, marker.lng ?? marker.lon], { icon: L.divIcon({ className: 'marker-icon ', html: iconDOM }) }).addTo(mapTarget.value.leafletObject).bindPopup(popupDOM);
            }
            else {
                return L.marker([marker.lat, marker.lng ?? marker.lon], { icon: L.divIcon({ className: 'marker-icon ', html: iconDOM }) }).addTo(mapTarget.value.leafletObject);
            }
        },
        
        async setMarkerWikiInfos(marker, existingMarker) {
            await this.setMarkerImgUrl(marker);
            
            let imageThumbnailWasFound = typeof marker.imgUrl != 'undefined' && marker.imgUrl && marker.imgUrl.length > 0;
            let imageWasFound = typeof marker.imgFullUrl != 'undefined' && marker.imgFullUrl && marker.imgFullUrl.length > 0;
            
            if (imageThumbnailWasFound) {
                CoreService.preloadImage(marker.imgUrl).then(() => {
                    if (!this.imageAlreadyLoaded.includes(marker.imgUrl))
                    this.imageAlreadyLoaded.push(marker.imgUrl);
                    $('#placeDialog > [slot="headline"]').css('background-image', `url(${marker.imgUrl})`);
                });
            }
            
            if (imageWasFound) {
                CoreService.preloadImage(marker.imgFullUrl).then(() => {
                    if (!this.imageAlreadyLoaded.includes(marker.imgFullUrl))
                    this.imageAlreadyLoaded.push(marker.imgFullUrl);
                    $('#picture-panel .place-picture').attr('src', marker.imgFullUrl);
                });
            }
        },
        
        setMarkerIcon(place) {
            let icon = 'location_on';
            let placeCategories = Array.from(place.categories);
            // get icon from json place categories
            
            while (placeCategories.length > 0) {
                let category = this.placeCategories.find(c => c.id == placeCategories[0]);
                let nextCategory = this.placeCategories.find(c => c.id == placeCategories[1]);
                
                if (category) {
                    // if same category as next, remove it to get more precise icon
                    if (nextCategory && category.id.split('.')[0] == nextCategory.id.split('.')[0] || category.noIconForMarker) {
                        placeCategories.shift();
                    }
                    
                    else {
                        return category.icon;
                    }
                }
                else {
                    placeCategories.shift();
                }
            }
            
            return icon;
        },
        
        async setMarkerImgUrl(marker) {
            let search = encodeURI(marker.place.wiki_and_media && marker.place.wiki_and_media.wikipedia ? marker.place.wiki_and_media.wikipedia.replace('fr:', '') : marker.place.address_line1 ?? marker.place.name);
            let wikiSearch = await this.$root.getWikiSearch(search);
            
            if (wikiSearch) {
                marker.wikiSearch = wikiSearch;
                marker.imgUrl = await this.$root.getWikiImage(wikiSearch.pageid);
                marker.imgFullUrl = await this.$root.getWikiImageFull(wikiSearch.pageid);
            }
        },
        
        // #endregion 
        
        // #region PLACE SEARCH
        
        async validplaceSearched(location) {
            this.selectedPlaceSearched = location;
            console.log('selectedPlaceSearched', location);
            
            // GET PLACE DETAILS
            if (this.selectedPlaceSearched != null) {
                let placeDetails = await this.getPlaceDetails(this.selectedPlaceSearched.properties.place_id);
                
                // ADD SEARCH MARKER
                let icon = 'add_location_alt';
                
                if (this.departureNeeded == 'true')
                icon = 'play_circle';
                else if (this.arrivalNeeded == 'true')
                icon = 'stop_circle';
                else if (typeof this.nightNeeded != 'undefined' && this.nightNeeded.length > 0)
                icon = 'hotel';
                
                let searchMarker = this.addMarker(location.properties, icon, 'marker-icon-searched');
                this.goTo(searchMarker._latlng, 15);
                
                // ADD ORIGINAL MARKER
                let place = CoreService.getArray(placeDetails.features)[0];
                
                if (!place) {
                    console.log('No place found for this search, replacing by selectedPlaceSearched.');
                    place = {
                        properties: {
                            place_id: location.properties.place_id,
                            name: location.properties.formatted,
                            address_line1: location.properties.address_line1,
                            lat: location.properties.lat,
                            lon: location.properties.lon,
                        },
                        geometry: {
                            coordinates: [location.properties.lon, location.properties.lat],
                        },
                    }
                }
                
                let marker = {
                    id: place.properties.place_id,
                    lat: typeof place.properties.lat != 'undefined' ? place.properties.lat : place.geometry.coordinates[1],
                    lng: typeof place.properties.lon != 'undefined' ? place.properties.lon : place.geometry.coordinates[0],
                    name: place.properties.name,
                    categories: place.properties.categories ? place.properties.categories.join(', ') : '',
                    imgUrl: '',
                    imgFullUrl: '',
                    icon: icon == 'add_location_alt' ? this.setMarkerIcon(place.properties) : icon,
                    wikiSearch: null,
                    place: place.properties,
                    leafletMarker: searchMarker,
                };
                
                this.markers.push(marker);
                this.setMakerEvents(marker, searchMarker);
                
                placesSearchedFoundDialog.close();
                filtersDialog.close();
            }
        },
        
        getPlaceDetails(placeId) {
            return fetch(`https://api.geoapify.com/v2/place-details?id=${placeId}&features=details,details.names&apiKey=837da8675243424e83d2fcb7aa570b9b`)
            .then(response => response.json())
            .then(data => { return data })
            .catch(error => console.log('error', error));
        },
        
        // #endregion
        
        // #region FILTERS
        
        initAddressAutoComplete() {
            setTimeout(() => {
                try {
                    const autocomplete = new GeocoderAutocomplete(
                    document.getElementById('autocomplete'), '837da8675243424e83d2fcb7aa570b9b', { /* Geocoder options */ });
                    
                    autocomplete.on('select', (location) => {
                        setTimeout(() => {
                            this.validplaceSearched(location);
                        }, 250);
                    });
                    
                    autocomplete.on('suggestions', (suggestions) => {
                        this.$root.savePlacesToApi(suggestions.map(s => s.properties));
                    });
                    
                    this.resetAutoComplete();
                    CoreService.handleKeyBoardOpening(document.querySelector('.autocomplete-container input'), () => { this.resizeMap(true); });
                    
                    this.addressAutoCompleteInitialized = true;
                }
                catch (error) {
                    this.addressAutoCompleteInitialized = false;
                    console.log('Autocomplete cannot initialize, maybe user change route since init.');
                }
            }, 1500);
        },
        
        openFiltersDialog() {
            filtersDialog.show();
        },
        
        openPlacesListDialog() {
            placesListDialog.show();
        },
        
        // #endregion
        
        // #region MODE TRIP
        
        sendStepToTrip(isCrossingPoint = false) {
            // TODO : check if a trip was selected before adding a step
            let params;
            
            // Place 
            if (!isCrossingPoint) {
                delete this.markerSelected.leafletMarker;
                params = {
                    stepToAdd: JSON.stringify({
                        place: this.markerSelected.place,
                        marker: this.markerSelected,
                        isCrossingPoint: false,
                        isDeparture: this.departureNeeded == 'true',
                        isArrival: this.arrivalNeeded == 'true',
                        isNight: typeof this.nightNeeded != 'undefined' && this.nightNeeded.length > 0,
                        dayIndex: typeof this.nightNeeded != 'undefined' && this.nightNeeded.length > 0 ? Number(this.nightNeeded) : null,
                    }),
                    tripId: CoreService.getSelectedTripId(),
                };
            }
            
            // Crossing point
            else {
                params = {
                    stepToAdd: JSON.stringify({
                        crossingPoint: this.selectedCrossingPoint,
                        marker: {
                            lat: Number(this.selectedCrossingPoint.lat),
                            lng: Number(this.selectedCrossingPoint.lon),
                        },
                        isCrossingPoint: true,
                        dayIndex: null,
                    }),
                    tripId: CoreService.getSelectedTripId(),
                };
            }
            
            // reset departure and arrival params
            this.$router.push({ name: 'map', params: { mode: 'explore' } });
            
            setTimeout(() => {
                this.$router.push({ name: 'trip', params: params });
            }, 250);
        },
        
        updateTripMarkers(tripSteps) {
            this.tripDaysLengend = [];
            this.tripMarkers = [];
            this.deleteLeafletMarkers(mapTrip, true);
            
            // Add days marker and polyline
            tripSteps.days.forEach((tripDay, tripDayIndex) => {
                let tripMarkers = tripDay.map(ts => ts.marker);
                
                if (tripMarkers.length == 0) {
                    console.log('No markers for this day. Day ' + (tripDayIndex + 1) + ' is empty.');
                    return;
                }
                
                let firstMarker = tripMarkers[0];
                
                let dayColor = this.tripDayColors[tripDayIndex % this.tripDayColors.length];
                this.tripDaysLengend.push({
                    color: dayColor,
                    index: tripDayIndex + 1,
                    firstMarkerLatLng: [firstMarker.lat, firstMarker.lng],
                });
                
                for (let tripMarkerIndex = 0; tripMarkerIndex < tripMarkers.length; tripMarkerIndex++) {
                    tripMarkers[tripMarkerIndex]['iconNumber'] = tripMarkerIndex + 1;
                    tripMarkers[tripMarkerIndex]['iconNumberColor'] = dayColor;
                    tripMarkers[tripMarkerIndex]['dayIndex'] = tripDayIndex;
                }
                
                // Add night to tripMarkers
                if (tripSteps.nights && tripSteps.nights.filter(n => n.dayIndex == tripDayIndex).length > 0) {
                    let nightMarker = tripSteps.nights.filter(n => n.dayIndex == tripDayIndex).first().marker;
                    nightMarker['iconClass'] = 'night';
                    tripMarkers.push(nightMarker);
                }
                
                this.buildMarkers(tripMarkers);
                this.drawPaths(tripMarkers, dayColor, tripDayIndex);
                
                if (tripSteps.days[tripDayIndex + 1] && tripSteps.days[tripDayIndex + 1].length > 0) {
                    this.drawInterDayPaths([tripMarkers.last(), tripSteps.days[tripDayIndex + 1].first().marker], dayColor);
                }
            });
            
            // Add departure markers
            if (tripSteps.departure) {
                this.buildMarkers([tripSteps.departure.marker], 'departure');
                
                if (tripSteps.days.length > 0 && tripSteps.days[0].length > 0)
                this.drawInterDayPaths([tripSteps.departure.marker, tripSteps.days[0].first().marker], '#22eb5d');
            }
            
            // Add arrival markers
            if (tripSteps.arrival) {
                this.buildMarkers([tripSteps.arrival.marker], 'arrival');
                this.drawInterDayPaths([tripSteps.arrival.marker, tripSteps.days.filter(d => d.length > 0).last().last().marker], '#e52000');
            }
        },
        
        initTabs() {
            if (typeof mapTab != 'undefined' && mapTab != null && typeof mapTab.addEventListener == 'function')
            mapTab.addEventListener('click', (event) => {
                this.resizeMap();
                this.$parent.sendTripMarkersToMap();
                
                setTimeout(() => {
                    mapTrip.value.leafletObject.invalidateSize();
                    
                    // handle new trip empty
                    if (JSON.parse(this.tripSteps).days.length > 0 && JSON.parse(this.tripSteps).days[0].length > 0) {
                        this.setDefaultTripMarkerView();
                    }
                }, 1);
            });
        },
        
        drawPaths(tripMarkers, dayColor, dayIndex) {
            let longLats = tripMarkers.map(tm => [parseFloat(tm.lng).toFixed(4), parseFloat(tm.lat).toFixed(4)]);
            
            if (longLats.length > 1) {
                this.calculateRoute(longLats, dayColor, dayIndex);
            }
        },
        
        async calculateRoute(waypoints, dayColor, dayIndex = null) {
            try {
                const waypointsString = waypoints.map(point => point.join(",")).join(";");
                
                // Appel à l’API OSRM
                const response = await axios.get(`https://router.project-osrm.org/route/v1/driving/${waypointsString}?overview=full&geometries=geojson`);
                
                const route = response.data.routes[0];
                const routeId = route.geometry.coordinates.join("-");
                const routePath = L.geoJSON(route.geometry, {
                    style: {
                        color: dayColor,
                        weight: 4,
                        opacity: 0.8,
                    },
                }).addTo(mapTrip.value.leafletObject);
                
                routePath.eachLayer(layer => {
                    if (layer._path) {
                        layer._path.setAttribute('data-day-index', dayIndex);
                    }
                });
                
                this.routeLayers.push({
                    id: routeId,
                    geoJSON: routePath,
                });
            }
            catch (error) {
                console.error("Erreur lors du calcul de l'itinéraire :", error);
            }
        },
        
        drawInterDayPaths(interDayMakers, dayColor) {
            let longLats = interDayMakers.map(tm => [parseFloat(tm.lng).toFixed(4), parseFloat(tm.lat).toFixed(4)]);
            this.calculateRoute(longLats, dayColor);
        },
        
        setDefaultTripMarkerView(dayIndex = null) {
            console.log('Set default trip marker view');
            let bounds;
            
            // Set bounds for a specific day from legend
            if (dayIndex != null) {
                let daySteps = this.$parent.trip.days[dayIndex];
                
                // Add night to daySteps
                if (this.$parent.trip.nights.filter(n => n.dayIndex == dayIndex).length > 0) {
                    daySteps.push(this.$parent.trip.nights.filter(n => n.dayIndex == dayIndex).first());
                }
                
                bounds = daySteps.map(ts => L.latLng(ts.marker.lat, ts.marker.lng));
                this.filterOpacityMarkerByDayIndex(dayIndex);
            }
            
            else {
                bounds = L.latLngBounds(this.tripMarkers.map(marker => L.latLng(marker.lat, marker.lng)));
            }
            
            mapTrip.value.leafletObject.fitBounds(bounds, { padding: [50, 50] });
        },
        
        filterOpacityMarkerByDayIndex(dayIndex) {
            // RESET OPACITY FILTER
            if ($(`.map-trip-days-legend li[data-day-index="${dayIndex}"]`).hasClass('active')) {
                this.resetFilterOpacityMarker();
            }
            else {
                $('.map-trip-days-legend li[data-day-index]').removeClass('active');
                $('span.marker-icon-number[data-day-index], .map-trip-days-legend li[data-day-index], path.leaflet-interactive[data-day-index]').css('opacity', 0.2);
                $(`span.marker-icon-number[data-day-index="${dayIndex}"], .map-trip-days-legend li[data-day-index="${dayIndex}"], path.leaflet-interactive[data-day-index="${dayIndex}"]`).css('opacity', 1);
                $(`.map-trip-days-legend li[data-day-index="${dayIndex}"]`).addClass('active');
            }
        },
        
        resetFilterOpacityMarker() {
            $('.map-trip-days-legend li[data-day-index]').removeClass('active');
            $('span.marker-icon-number[data-day-index], .map-trip-days-legend li[data-day-index]').css('opacity', 1);
            setTimeout(() => {
                this.setDefaultTripMarkerView();
            }, 250);
        },
        
        // #endregion
        
        // #region PARAMS NEEDED
        
        handleRouteParams() {
            if (this.departureNeeded && this.departureNeeded == 'true') {
                this.handleDepartureNeeded();
            }
            
            if (this.arrivalNeeded && this.arrivalNeeded == 'true') {
                this.handleArrivalNeeded();
            }
            
            if (typeof this.nightNeeded != 'undefined' && !Number.isNaN(this.nightNeeded) && Number(this.nightNeeded) > -1 && this.nightNeeded.length > 0) {
                this.handleNightNeeded();
            }
            
            if (typeof this.placeId != 'undefined' && this.placeId.length > 0) {
                this.handlePlaceId(this.placeId);
            }
        },
        
        handleDepartureNeeded() {
            if (document.querySelector('.geoapify-autocomplete-input')) {
                document.querySelector('.geoapify-autocomplete-input').value = '';
                document.querySelector('.geoapify-autocomplete-input').placeholder = 'Search your starting point';
                document.querySelector('.geoapify-autocomplete-input').focus();
            }
            
            else {
                setTimeout(() => {
                    this.handleDepartureNeeded();
                }, 1500);
            }
        },
        
        handleArrivalNeeded() {
            if (document.querySelector('.geoapify-autocomplete-input')) {
                document.querySelector('.geoapify-autocomplete-input').value = '';
                document.querySelector('.geoapify-autocomplete-input').placeholder = 'Search your ending point';
                document.querySelector('.geoapify-autocomplete-input').focus();
            }
            
            else {
                setTimeout(() => {
                    this.handleArrivalNeeded();
                }, 1500);
            }
        },
        
        handleNightNeeded() {
            if (document.querySelector('.geoapify-autocomplete-input')) {
                document.querySelector('.geoapify-autocomplete-input').value = '';
                document.querySelector('.geoapify-autocomplete-input').placeholder = 'Search your night place';
                document.querySelector('.geoapify-autocomplete-input').focus();
            }
            
            else {
                setTimeout(() => {
                    this.handleNightNeeded();
                }, 1500);
            }
        },
        
        resetAutoComplete() {
            if (document.querySelector('.geoapify-autocomplete-input')) {
                document.querySelector('.geoapify-autocomplete-input').value = '';
                document.querySelector('.geoapify-autocomplete-input').placeholder = 'Search a place';
                document.querySelector('.autocomplete-container').style.opacity = 1;
            }
            
            else {
                setTimeout(() => {
                    this.resetAutoComplete();
                }, 1500);
            }
        },
        
        handlePlaceId(placeId) {
            this.getPlaceDetails(placeId).then(response => {
                if (response.features[0].geometry.type == 'Polygon') {
                    // draw lines around the city limit 
                    let latLongs = response.features[0].geometry.coordinates[0].map(c => [c[1], c[0]]);
                    L.polygon(latLongs, { color: 'red' }).addTo(mapExplore.value.leafletObject);
                    
                    // get appropriate zoom to fit the polygon
                    let bounds = L.latLngBounds(latLongs);
                    mapExplore.value.leafletObject.fitBounds(bounds, { padding: [50, 50] });
                }
                else {
                    this.goTo([response.features[0].properties.lat, response.features[0].properties.lon], 20);
                }
            });
        },
        
        // #endregion
        
        // #region MENU
        
        async handleCrossingPoint() {
            // Add manual marker to the center of the map 
            let mapTarget = this.isExploreMode() ? mapExplore : mapTrip;
            let latLng = mapTarget.value.leafletObject.getCenter();
            
            // Remove existing marker if any
            if (this.selectedCrossingPointMarker) {
                this.selectedCrossingPointMarker._icon.remove();
                this.selectedCrossingPointMarker = null;
            }

            // Create marker with an icon and a popup
            this.selectedCrossingPointMarker = this.addMarker({ id: 'crossing-point', lat: latLng.lat, lng: latLng.lng }, 'add_location_alt', 'marker-icon-searched');
            
            // Handle click on the marker to open the menu
            this.selectedCrossingPointMarker.on('click', async () => {
                let result = await this.getPlaceByLatLng(latLng.lat, latLng.lng);
                this.selectedCrossingPoint = result ?? null;
                let address = result ? result.display_name : 'No address found';
                $('#addStepHereDialog #addStepHereForm').text(address);
                addStepHereDialog.show();
            });
            
            addStepHereDialog.addEventListener('close', (latLng) => {
                this.deleteCrossingPoint();
            });
        },
        
        deleteCrossingPoint() {
            if (this.selectedCrossingPointMarker) {
                this.selectedCrossingPointMarker._icon.remove();
                this.selectedCrossingPointMarker = null;
            }
        },
        
        // #endregion
    }
}

</script>

<style lang="scss">
@import "../assets/scss/map.scss";
@import "~@geoapify/geocoder-autocomplete/styles/round-borders.css";
</style>
