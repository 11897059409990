<template>
    <div class="places">
        <h1>{{ title }}</h1>

        <div :class="['place-slider', { 'expanded': isExpanded }]" :data-mode="mode">
            <div class="place-slider-item hidden" v-for="place in placesWithImage" :key="place.place_id"
                @click="goToPlace(place.place_id)" :data-place-id="place.place_id">
                <div class="place-slider-item-image-container">
                    <img v-if="place.image" loading="lazy" class="place-slider-item-image" :src="place.image"
                        :alt="place.name">
                    <div v-if="!place.image" class="place-slider-item-image-backdrop animated-background"></div>
                </div>
                <div class="place-slider-item-content">
                    <h3>{{ place.name ?? place.city }}</h3>
                </div>
            </div>
        </div>
        <div class="toggle-slider-container">
            <md-filled-tonal-button @click="toggleExpanded" class="toggle-slider">{{ isExpanded ? 'Less' : 'More' }}</md-filled-tonal-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Places',
    props: ['mode'],
    inject: ['store'],
    components: {
    },
    data() {
        return {
            places: [],
            placesWithImage: [],
            sliderIsDragging: false,
            title: '',
            isExpanded: false,
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.title = this.mode == 'places' ? 'Top places searched' : 'Top cities searched';
            this.places = this.mode == 'places' ? await this.$root.getTopPlacesFromApi() : await this.$root.getTopCitiesFromApi();

            if (this.places && this.places.length > 0) {
                await this.setPlacesWithImage();
            }

            this.initSlider();
        },

        async initSlider() {
            this.$nextTick(() => {
                const el = this.$refs.slider;
                if (el && el.scrollWidth > el.clientWidth) {
                    CoreService.initSlider('.place-slider[data-mode=' + this.mode + ']');
                }
            })
        },

        toggleExpanded() {
            this.isExpanded = !this.isExpanded;
            this.$nextTick(() => {
                this.initSlider();
            })
        },

        // #region PLACES

        async setPlacesWithImage() {
            this.placesWithImage = [];

            for (let i = 0; i < this.places.length; i++) {
                let place = this.places[i];
                let wikiParams = place.name ? place.name : place.city + ', ' + place.country;
                let placeWikiPage = await this.$root.getWikiSearch(wikiParams);

                if (placeWikiPage) {
                    place.image = await this.$root.getWikiImage(placeWikiPage.pageid);

                    // Image found
                    if (place.image) {
                        await CoreService.preloadImage(place.image).then(() => {
                            this.placesWithImage.push(place);
                            this.showPlace(place.place_id);
                        });
                    }

                    // Image not found
                    else {
                        this.placesWithImage.push(place);
                        this.showPlace(place.place_id);
                    }
                }

                // No wiki page found
                else {
                    this.placesWithImage.push(place);
                    this.showPlace(place.place_id);
                }
            }
        },

        showPlace(placeId) {
            setTimeout(() => {
                $('.place-slider-item[data-place-id=' + placeId + ']').removeClass('hidden');
            }, 250);
        },

        goToPlace(placeId) {
            if (this.sliderIsDragging) return;

            let params = {
                mode: 'explore',
                departureNeeded: false,
                arrivalNeeded: false,
                nightNeeded: -1,
                placeId: placeId,
            };
            this.$router.push({ name: 'map', params: params });
        },

        // #endregion
    }
}
</script>
