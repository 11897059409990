<template>
    <nav :class="isMobile ? 'bottom' : 'top'">
        <!-- menu mobile/tablette -->
        <ul v-if="isMobile">
            <li>
                <router-link :to="{ name: 'home' }">
                    <md-icon>home</md-icon>
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'map', params: { mode: 'explore' } }">
                    <md-icon>travel_explore</md-icon>
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'trip', params: { tripId: tripId } }">
                    <md-icon>multiple_stop</md-icon>
                </router-link>
            </li>
            <li>
                <a @click="logout">
                    <md-icon>logout</md-icon>
                </a>
            </li>
        </ul>

        <!-- menu desktop -->
        <div v-else class="desktop-nav-bar">
            <img src="../assets/img/logo.png" alt="logo" class="logo" />

            <router-link to="/" class="nav-item" exact-active-class="active">
                <md-icon>home</md-icon>
                <span>Accueil</span>
            </router-link>
            <router-link :to="{ name: 'map', params: { mode: 'explore' } }" class="nav-item"
                exact-active-class="active">
                <md-icon>travel_explore</md-icon>
                <span>Explore</span>
            </router-link>
            <router-link :to="{ name: 'trip', params: { tripId: tripId } }" class="nav-item"
                exact-active-class="active">
                <md-icon>multiple_stop</md-icon>
                <span>Trip</span>
            </router-link>
            <a @click="logout" class="nav-item">
                <md-icon>logout</md-icon>
            </a>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'Nav',
    inject: ['store'],
    props: ['tripId'],
    data() {
        return {
            windowWidth: window.innerWidth,
        }
    },
    computed: {
        isMobile() {
            return this.windowWidth < 1024;
        },
    },
    mounted() {
        window.addEventListener('resize', this.updateWindowWidth);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateWindowWidth);
    },
    methods: {
        updateWindowWidth() {
            this.windowWidth = window.innerWidth;
        },
        logout() {
            this.store.dispatch('logout');
            this.$router.push('/');
        }
    }
}
</script>

<style lang="scss">
@import "../assets/scss/nav.scss";
</style>