<template>
    <Nav v-if="isLoggedIn" :tripId="tripId" />
    
    <main class="container" v-if="isLoggedIn">
        <router-view v-slot="{ Component }">
            <keep-alive>
                <component :is="Component" />
            </keep-alive>
        </router-view>
    </main>
    
    <Auth v-else />
</template>

<script>
import jQuery from 'jquery';
const $ = jQuery;
window.$ = $;

import axios from 'axios';
import store from './store';

axios.interceptors.request.use((config) => {
    if (!config.url.includes('wiki') && !config.url.includes('router.project-osrm.org')) {
        const token = store.state.token;
        
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
    }
    
    return config;
});

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

import Nav from './components/Nav.vue';
import Auth from './components/auth/Auth.vue';

export default {
    components: {
        Nav,
        Auth,
    },
    provide() {
        return { store };
    },
    data() {
        return {
            tripId: null,
            language: 'en',
        }
    },
    created() {
        this.tripId = CoreService.getSelectedTripId();
    },
    mounted() {
        document.addEventListener('swUpdated', this.showRefreshUI);
        this.setTextFieldAutoCompleteStyle();
    },
    computed: {
        isLoggedIn() {
            return !!this.$store.getters.isLoggedIn;
        }
    },
    methods: {
        // #region COMMON
        
        showRefreshUI(event) {
            // Option 1 : Rafraîchir automatiquement
            if (confirm("Une nouvelle version de l'application est disponible. Voulez-vous rafraîchir ?")) {
                const worker = event.detail.waiting;
                if (worker) {
                    worker.postMessage({ type: 'SKIP_WAITING' });
                    worker.addEventListener('statechange', (e) => {
                        if (e.target.state === 'activated') {
                            window.location.reload();
                        }
                    });
                }
            }
            // Option 2 : Afficher une bannière ou une notification
            // Vous pouvez également afficher un message personnalisable ou un bouton pour rafraîchir
        },
        
        setTextFieldAutoCompleteStyle() {
            const inputs = document.querySelectorAll('md-outlined-text-field');
            inputs.forEach(input => {
                var sheet = new CSSStyleSheet;
                let parent = input.parentElement;
                
                while (parent) {
                    const bgColor = window.getComputedStyle(parent).backgroundColor;
                    if (bgColor !== 'rgba(255, 255, 255, 0)' && bgColor !== 'rgb(255, 255, 255)' && bgColor !== 'rgba(0, 0, 0, 0)' && bgColor !== 'rgb(0, 0, 0, 0)' && bgColor !== 'transparent') {
                        sheet.replaceSync(`input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus { -webkit-text-fill-color: #ffffff; -webkit-box-shadow: 0 0 0px 40rem ${bgColor} inset; }`);
                        break;
                    }
                    parent = parent.parentElement;
                }
                
                input.shadowRoot.adoptedStyleSheets.push(sheet);
            });
        },
        
        toastMessage(message, type = 'info', duration = 3000) {
            toast(message, {
                transition: 'flip',
                theme: "colored",
                type: type,
                position: 'top-right',
                duration: duration,
            });
        },
        
        // #endregion
        
        // #region API
        
        handleForbiddenError(error) {
            if (error.response.status == 403) {
                store.dispatch('logout');
                this.$router.push('/');
            }
            else {
                throw error;
            }
        },
        
        // #region TRIP
        
        async getTripsFromApi() {
            return await axios.get(process.env.VUE_APP_API_URL + '/trips')
            .then(response => response.data)
            .catch(error => {
                this.$root.toastMessage('An error occured while getting trips', 'error');
                this.handleForbiddenError(error);
            });
        },
        
        async getTripFromApi(tripId) {
            return await axios.get(process.env.VUE_APP_API_URL + '/trips/' + tripId)
            .then(response => response.data)
            .catch(error => {
                this.$root.toastMessage('An error occured while getting the trip', 'error');
                this.handleForbiddenError(error);
            });
        },
        
        async saveTripToApi(trip) {
            delete trip._id;
            
            // create a deep copy of the trip to avoid modifying the original trip
            trip = JSON.parse(JSON.stringify(trip));
            await this.prepareTripForApi(trip);
            
            return await axios.put(process.env.VUE_APP_API_URL + '/trips/' + trip.id, trip, { headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                this.$root.toastMessage('Trip saved !', 'success');
                return response.data;
            })
            .catch(error => {
                this.$root.toastMessage('Error while saving trip !', 'error');
                this.handleForbiddenError(error);
            });
        },
        
        async prepareTripForApi(trip) {
            let placesToSave = [];
            
            let dayStepPlaces = trip.days.map(day => day.map(step => step.place));
            let nightPlaces = trip.nights.map(night => night.place);
            
            placesToSave = placesToSave.concat(...dayStepPlaces, nightPlaces);
            
            trip.days.forEach(day => {
                day.forEach(step => {
                    if (step.place) {
                        step.place_id = step.place.place_id;
                        delete step.place;
                    }
                });
            });
            
            trip.nights.forEach(night => {
                night.place_id = night.place.place_id;
                delete night.place;
            });
            
            // save places
            await this.savePlacesToApi(placesToSave.filter(place => place !== null && place !== undefined));
        },
        
        async deleteTripFromApi(tripId) {
            return await axios.delete(process.env.VUE_APP_API_URL + '/trips/' + tripId)
            .then(response => response.data)
            .catch(error => {
                this.handleForbiddenError(error);
            });
        },
        
        async addStepFileToApi(fileInfos) {
            const formData = new FormData();
            formData.append('file', fileInfos.file);
            
            return await axios.post(process.env.VUE_APP_API_URL + '/trips/' + fileInfos.tripId + '/day/' + fileInfos.dayIndex + '/step/' + fileInfos.stepIndex + '/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                this.$root.toastMessage('File uploaded !', 'success');
                addStepFileDialog.close();
                return response.data;
            })
            .catch(error => {
                this.$root.toastMessage('An error occured while adding file to step', 'error');
                this.handleForbiddenError(error);
            });
        },
        
        async getStepFileFromApi(tripId, dayIndex, stepIndex) {
            return await axios.get(process.env.VUE_APP_API_URL + '/trips/' + tripId + '/day/' + dayIndex + '/step/' + stepIndex + '/file', {
                responseType: 'blob'
            })
            .then(response => response.data)
            .catch(error => {
                this.$root.toastMessage('An error occured while getting file from step', 'error');
                this.handleForbiddenError(error);
            });
        },
        
        async deleteStepFileFromApi(tripId, dayIndex, stepIndex) {
            return await axios.delete(process.env.VUE_APP_API_URL + '/trips/' + tripId + '/day/' + dayIndex + '/step/' + stepIndex + '/file')
            .then(response => {
                this.$root.toastMessage('File deleted !', 'success');
                return response.data;
            })
            .catch(error => {
                this.$root.toastMessage('An error occured while deleting file from step', 'error');
                this.handleForbiddenError(error);
            });
        },
        
        // #endregion
        
        // #region PLACES
        
        async getPlacesFromApi() {
            return await axios.get(process.env.VUE_APP_API_URL + '/places')
            .then(response => response.data)
            .catch(error => {
                this.$root.toastMessage('An error occured while getting places', 'error');
                this.handleForbiddenError(error);
            });
        },
        
        async getPlaceFromApi(placeId) {
            return await axios.get(process.env.VUE_APP_API_URL + '/places/' + placeId)
            .then(response => response.data)
            .catch(error => {
                this.$root.toastMessage('An error occured while getting the place', 'error');
                this.handleForbiddenError(error);
            });
        },
        
        async savePlaceToApi(place) {
            delete place._id;
            delete place.datasource;
            
            return await axios.put(process.env.VUE_APP_API_URL + '/places/' + place.place_id, place, { headers: { 'Content-Type': 'application/json' } })
            .then(response => {
                console.log('Place saved !', place);
                return response.data;
            })
            .catch(error => {
                this.handleForbiddenError(error);
            });
        },
        
        async savePlacesToApi(places) {
            places.forEach(async place => {
                await this.savePlaceToApi(place);
            });
        },
        
        async deletePlaceFromApi(placeId) {
            return await axios.delete(process.env.VUE_APP_API_URL + '/places/' + placeId)
            .then(response => response.data)
            .catch(error => {
                this.handleForbiddenError(error);
            });
        },
        
        async getTopPlacesFromApi(limit = 20) {
            return await axios.get(process.env.VUE_APP_API_URL + '/places/topPlaces/' + limit)
            .then(response => response.data)
            .catch(error => {
                this.$root.toastMessage('An error occured while getting top places', 'error');
                this.handleForbiddenError(error);
            });
        },
        
        async getTopCitiesFromApi(limit = 20) {
            return await axios.get(process.env.VUE_APP_API_URL + '/places/topCities/' + limit)
            .then(response => response.data)
            .catch(error => {
                this.$root.toastMessage('An error occured while getting top cities', 'error');
                this.handleForbiddenError(error);
            });
        },
        
        // #endregion
        
        // #endregion
        
        // #region WIKI API
        
        async getWikiSearch(search) {
            if (!search) return null;
            
            try {
                const res = await axios.get(`https://en.wikipedia.org/w/api.php`, {
                    params: {
                        format: 'json',
                        action: 'query',
                        list: 'search',
                        srsearch: search,
                        origin: '*'
                    }
                });
                
                const page = res.data.query?.search?.[0];
                return page || null;
                
            } catch (e) {
                console.error('[WIKI SEARCH ERROR]', e.message);
                return null;
            }
        },
        
        
        async getWikiImage(pageId) {
            if (!pageId) return null;
            
            try {
                const res = await axios.get(`https://en.wikipedia.org/w/api.php`, {
                    params: {
                        format: 'json',
                        action: 'query',
                        prop: 'pageimages',
                        piprop: 'thumbnail',
                        pithumbsize: 600,
                        formatversion: 2,
                        pageids: pageId,
                        origin: '*'
                    }
                });
                
                return res.data.query?.pages?.[0]?.thumbnail?.source || null;
                
            } catch (e) {
                console.error('[WIKI IMAGE ERROR]', e.message);
                return null;
            }
        },
        
        async getWikiImageFull(pageId) {
            return axios.get(`https://en.wikipedia.org/w/api.php?format=json&action=query&piprop=original&pithumbsize=1000&formatversion=2&prop=pageimages%7Cpageterms&origin=*&pageids=${pageId}`)
            .then(response => {
                if (response.data.query.pages[0].original)
                return response.data.query.pages[0].original.source;
            })
            .catch(error => console.log(error.message));
        },
        
        // #endregion
    }
}

</script>
