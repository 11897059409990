<template>
    <div class="home">
        <Trips />
        <Places mode="cities" />
        <Places mode="places" />
    </div>
</template>

<script>
import Trips from '@/components/Trips.vue';
import Places from '@/components/Places.vue';

export default {
    name: 'Home',
    components: {
        Trips,
        Places,
    },
    data() {
        return {
        }
    },
    created() {
    },
    mounted() {
        this.init();
    },
    watch: {
        $route (to, from){
            
        },  
    },
    methods: {
        init: function () {
            
        },
    }
}
</script>

<style lang="scss">
@import "../assets/scss/home.scss";
@import "../assets/scss/trips.scss";
@import "../assets/scss/slider.scss";
</style>
