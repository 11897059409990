<template>
    <div class="trips">
        <h1>{{ title }}</h1>

        <div class="trip-slider">
            <div class="trip-slider-item hidden" v-for="trip in tripsWithImage" :key="trip.id"
                @click="goToTrip(trip.id)" :data-trip-id="trip.id">
                <div class="trip-slider-item-image-container">
                    <img v-if="trip.image" loading="lazy" class="trip-slider-item-image" :src="trip.image" :alt="trip.name">
                    <div v-if="!trip.image" class="trip-slider-item-image-backdrop animated-background"></div>
                </div>
                <div class="trip-slider-item-content">
                    <h3>{{ trip.name }}</h3>
                    <span v-if="trip.days.length > 0" class="trip-slider-item-infos">
                        <span>{{ trip.days.length + (trip.days.length > 1 ? ' days' : ' day') }}</span>
                        <span>{{ trip.days.flat().length + (trip.days.flat().length > 1 ? ' steps' : ' step') }}</span>
                    </span>
                    <span v-if="trip.days.length == 0">Your trip is empty, complete it !</span>
                </div>
            </div>
            <div class="trip-slider-item add-new-trip hidden" @click="addNewTrip">
                <div class="trip-slider-item-image-backdrop animated-background"></div>
                <div class="flex-container">
                    <md-elevated-button>
                        <md-icon class="bold">add</md-icon>
                    </md-elevated-button>
                    <span>Add new trip</span>
                </div>
            </div>
        </div>

        <md-dialog id="tripDialog">
            <div slot="headline">Add new trip</div>
            <form slot="content" id="tripForm" method="dialog">
                <md-outlined-text-field id="tripNameTextField" label="Name" value="" type="text"
                    autocomplete="off"></md-outlined-text-field>

                <div class="md-slider-container">
                    <span slot="label">Default day step length</span>
                    <md-slider id="tripDefaultDayStepLengthSlider" min="1" max="10" value="5" labeled></md-slider>
                </div>

                <div class="md-radio-container" role="radiogroup" aria-labelledby="optimization-group-title">
                    <span slot="label" id="optimization-group-title">Optimization by :</span>

                    <div class="md-radio-item">
                        <md-radio id="timeRadio" name="optimizationMode" value="time" checked></md-radio>
                        <label for="timeRadio">Time</label>
                    </div>
                    <div class="md-radio-item">
                        <md-radio id="distanceRadio" name="optimizationMode" value="distance"></md-radio>
                        <label for="distanceRadio">Distance</label>
                    </div>
                </div>
            </form>
            <div slot="actions">
                <md-text-button form="tripForm" value="cancel">Cancel</md-text-button>
                <md-text-button form="tripForm" value="ok" disabled @click="validNewTrip">Valid</md-text-button>
            </div>
        </md-dialog>
    </div>
</template>

<script>
import { CoreService } from '@/assets/js/core';
import axios from 'axios';

export default {
    name: 'Trips',
    inject: ['store'],
    components: {
    },
    data() {
        return {
            trips: [],
            tripsWithImage: [],
            newTrip: {
                name: '',
                defaultDayStepLength: 5,
                optimizationMode: 'time',
            },

            dialogEventsInitialized: false,
            sliderIsDragging: false,

            title: '',
        }
    },
    created() {
    },
    mounted() {
        this.init();
        CoreService.initDialogs();
    },
    watch: {
        $route(to, from) {
            if (to.name == 'home' || to.name == 'trip' && !CoreService.getSelectedTripId()) {
                setTimeout(() => {
                    this.resetTrips();
                }, 100);
            }
        },
    },
    methods: {
        async init() {
            this.trips = await this.$root.getTripsFromApi();

            if (this.trips && this.trips.length > 0) {
                if (this.$route.name != 'trip') {
                    this.title = 'Your trips';
                }

                else {
                    this.title = 'Select a trip to continue';
                }

                this.setTripsWithImage();
            }
            else {
                this.title = 'Create your first trip';
                this.showAddNewTrip();
            }

            if (!this.dialogEventsInitialized) {
                this.initDialogEvents();
            }

            CoreService.initSlider('.trip-slider');
        },

        // #region TRIPS

        async setTripsWithImage() {
            this.tripsWithImage = [];

            for (let index = 0; index < this.trips.length; index++) {
                let trip = this.trips[index];
                let cities = [];
                trip.days.forEach(day => {
                    day.map(d => d).forEach(step => {
                        if (step.place) {
                            cities.push(step.place.city ?? step.place.county ?? step.place.state ?? step.place.country);
                        }
                    });
                });

                // DISTINCT CITIES
                cities = cities.filter((v, i, a) => a.indexOf(v) === i);

                if (cities.filter(c => c).length > 0) {
                    let locationName = cities.first();
                    let cityWikiPage = await this.$root.getWikiSearch(locationName);
                    trip.image = await this.$root.getWikiImage(cityWikiPage.pageid);

                    // Image not found with firt location name
                    if (!trip.image) {
                        while (!trip.image && cities.length > 0) {
                            cities.shift();
                            locationName = cities.first();
                            cityWikiPage = await this.$root.getWikiSearch(locationName);
                            trip.image = await this.$root.getWikiImage(cityWikiPage.pageid);
                        }
                    }

                    // Image found
                    if (trip.image) {
                        await CoreService.preloadImage(trip.image).then(() => {
                            this.tripsWithImage.push(trip);
                            this.showTrip(trip.id);
                        });
                    }

                    // Image not found with any location name
                    else {
                        this.tripsWithImage.push(trip);
                        this.showTrip(trip.id);
                    }
                }

                else {
                    this.tripsWithImage.push(trip);
                    this.showTrip(trip.id);
                }

                if (index === this.trips.length - 1) {
                    this.showAddNewTrip();
                }
            }
        },

        showTrip(tripId) {
            setTimeout(() => {
                $('.trip-slider-item[data-trip-id=' + tripId + ']').removeClass('hidden');
            }, 250);
        },

        showAddNewTrip() {
            setTimeout(() => {
                $('.trip-slider-item.add-new-trip').show();
                $('.trip-slider-item.add-new-trip').removeClass('hidden');
            }, 1000);
        },

        goToTrip(tripId) {
            if (this.sliderIsDragging) return;

            CoreService.setSelectedTripId(tripId);
            this.$root.tripId = tripId;
            this.$router.push({ name: 'trip', params: { tripId: tripId } });
        },

        addNewTrip() {
            tripDialog.show();
        },

        resetTrips() {
            $('.trip-slider-item.add-new-trip').hide();
            this.tripsWithImage = [];
            this.trips = [];

            setTimeout(() => {
                this.init();
            }, 500);
        },

        // #endregion

        // #region DIALOG

        initDialogEvents() {
            if (typeof tripNameTextField != 'undefined' && tripNameTextField) {
                this.dialogEventsInitialized = true;
                let textField = tripNameTextField.length > 1 ? tripNameTextField[1] : tripNameTextField;

                textField.addEventListener('keyup', () => {
                    this.newTrip.name = tripNameTextField.value;
                    this.checkTripForm();
                });

                textField.addEventListener('change', () => {
                    this.newTrip.name = tripNameTextField.value;
                    this.checkTripForm();
                });
            }
        },

        checkTripForm() {
            if (this.newTrip.name.length > 0)
                $('#tripDialog md-text-button[value="ok"]').removeAttr('disabled');
            else
                $('#tripDialog md-text-button[value="ok"]').attr('disabled', 'disabled');
        },

        validNewTrip() {
            let trip = {
                id: CoreService.getGuid(),
                userId: this.$store.getters.userId,
                name: this.newTrip.name,
                defaultDayStepLength: Number(tripDefaultDayStepLengthSlider.value),
                optimizationMode: timeRadio.checked ? 'time' : 'distance',
                days: [],
                nights: [],
            };

            CoreService.saveTrip(trip);
            this.$root.saveTripToApi(trip);

            this.trips.push(trip);
            this.setTripsWithImage();

            tripDialog.close();
        },

        // #endregion
    }
}
</script>
