<template>
    <div class="auth">
        <div class="auth-container">
            <Logo :sizeClass="'default'" :type="'default'" />
            
            <Transition>
                <div v-if="authMode == 'login'" class="login-component">
                    <Login />

                    <md-divider></md-divider>
                    <md-outlined-button @click="switchForm">Register</md-outlined-button>
                </div>
                <div v-else-if="authMode == 'register'" class="register-component">
                    <Register />

                    <md-divider></md-divider>
                    <md-outlined-button id="backToLoginButton" @click="switchForm">Back to login</md-outlined-button>
                </div>
            </Transition>
        </div>
    </div>
</template>

<script>
import Logo from '@/components/Logo.vue';
import Login from '@/components/auth/Login.vue';
import Register from '@/components/auth/Register.vue';

export default {
    inject: ['store'],
    components: {
        Logo, 
        Login,
        Register,
    },
    data() {
        return {
            authMode: 'login',
        }
    },
    methods:{
        switchForm() {
            this.authMode = this.authMode === 'login' ? 'register' : 'login';
        }
    },
}

</script>

<style lang="scss">
@import '@/assets/scss/auth.scss';
</style>
